@import "../../../static/variables";

.experience-container {
    width: 50%;
    
    b {
        color: $orange-dark;
    }
}

.margin-bottom {
    margin-bottom: 3em;
}

.education-container {
    max-width: 85%;
    width: 700px;
}

.title {
    text-decoration: overline;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.see-more {
    color: $weak-text-color;
    font-style: italic;
    margin-bottom: 10px;
}

.modal-links {
    a {
        color: $orange-dark;
        font-size: $p-size;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
}

@media screen and (max-width: $phone-width) {
    .experience-container {
        padding-left: 10%;
        padding-right: 10%;
        width: 90%;
        margin-top: 2.5em;
    }

    .margin-bottom {
        margin-bottom: 2em;
    }

    .education-container {
        max-width: 100%;
        width: 100%;
    }
}

@media screen and (max-width: $phone-width-small) {
    .experience-container {
        padding-left: 0;
        padding-right: 0;
    }
}

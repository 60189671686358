@import "../hi/hi.style.scss";
@import "../../../static/variables";

.svg-container {
    margin-left: 14vw;
    animation: fade-in $base-delay3+$sentence-pause*5s linear;
}

// .landing-page-svg {
//     width: 500px;
//     height: 100%;
// }
.landing-page-svg {
    width: calc(15em + 15vw);
}


// SVG ANIMATIONS

// CLOUDS
.cloud-animation-common {
    transform-origin: center;
    transform-box: fill-box;
}

#cloud1 {
    @extend .cloud-animation-common;
    animation: cloud1-animation 10s linear infinite;
}

#cloud2 {
    @extend .cloud-animation-common;
    animation: cloud2-animation 10s linear infinite;
}

#cloud3 {
    @extend .cloud-animation-common;
    animation: cloud3-animation 10s linear infinite;
}

#cloud4 {
    @extend .cloud-animation-common;
    animation: cloud4-animation 10s linear infinite;
}

#cloud5 {
    @extend .cloud-animation-common;
    animation: cloud5-animation 10s linear infinite;
}

#cloud6 {
    @extend .cloud-animation-common;
    animation: cloud6-animation 10s linear infinite;
}


@keyframes cloud1-animation {
    0% {
        transform: translate(-4em,0);
        opacity: 0;
    }
    33% {
        transform: translate(1em,0.5em);
        opacity: 0.07;
    }
    66% {
        transform: translate(6em,0);
        opacity: 0.07;
    }
    100% {
        transform: translate(11em,0.5em);
        opacity: 0;
    }
}

@keyframes cloud2-animation {
    0% {
        transform: translate(-5.5em,0);
        opacity: 0;
    }
    33% {
        transform: translate(-0.5em,0.5em);
        opacity: 0.07;   
    }
    66% {
        transform: translate(4.5em,0);
        opacity: 0.07;
    }
    100% {
        transform: translate(9.5em,0.5em);
        opacity: 0;
    }
}

@keyframes cloud3-animation {
    0% {
        transform: translate(-8em,0.5em);
        opacity: 0;
    }
    33% {
        transform: translate(-3em,0);
        opacity: 0.07;   
    }
    66% {
        transform: translate(2em,0.5em);
        opacity: 0.07;
    }
    100% {
        transform: translate(7em,0);
        opacity: 0;
    }
}

@keyframes cloud4-animation {
    0% {
        transform: translate(-8.5em,-1em);
        opacity: 0;
    }
    33% {
        transform: translate(-5em,0);
        opacity: 0.07;   
    }
    66% {
        transform: translate(-1.5em,1em);
        opacity: 0.07;
    }
    100% {
        transform: translate(2.5em,0em);
        opacity: 0;
    }
}

@keyframes cloud5-animation {
    0% {
        transform: translate(-1em,0);
        opacity: 0;
    }
    33% {
        transform: translate(2em,1em);
        opacity: 0.07;   
    }
    66% {
        transform: translate(5em,1em);
        opacity: 0.07;
    }
    100% {
        transform: translate(8em,1.5em);
        opacity: 0;
    }
}

@keyframes cloud6-animation {
    0% {
        transform: translate(-3.5em,1em);
        opacity: 0;
    }
    33% {
        transform: translate(-2em,0.5em);
        opacity: 0.07;   
    }
    66% {
        transform: translate(-0.5em,0em);
        opacity: 0.07;
    }
    100% {
        transform: translate(1em,0.5em);
        opacity: 0;
    }
}


// PLANTS
.plant-animation-common {
    transform-origin: bottom center;
    transform-box: fill-box;
}

#plant1 {
    @extend .plant-animation-common;
    animation: plant1-animation 3s ease-in-out infinite;
}
#plant2 {
    @extend .plant-animation-common;
    animation: plant2-animation 3s ease-in-out infinite;
}

@keyframes plant1-animation {
    0% {
        transform: rotateZ(2deg);
    }
    50% {
        transform: rotateZ(7deg);
    }
    100% {
        transform: rotateZ(2deg);
    }
}

@keyframes plant2-animation {
    0% {
        transform: rotateZ(-2deg);
    }
    50% {
        transform: rotateZ(-7deg);
    }
    100% {
        transform: rotateZ(-2deg);
    }
}


// HEAD
$rotation: 6;

#head {
    transform-origin: bottom center;
    transform-box: fill-box;

    animation: head-animation 1s linear infinite;
}

@keyframes head-animation {
    0% {
        transform: rotateZ(-$rotation*1deg);
    }
    50% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(-$rotation*1deg);
    }
}


@media screen and (max-width: $ipad-width) {    
    .svg-container {
        margin-left: 120px;
        animation: fade-in $base-delay3+$sentence-pause*5s linear;
    }

    .landing-page-svg {
        width: calc(15em + 15vw);
    }
}

@media screen and (max-width: $phone-width) {
    .svg-container {
        margin-left: 0px;
        animation: fade-in $base-delay3+$sentence-pause*4s linear;
    }

    .landing-page-svg {
        width: calc(15em + 15vw);
    }
}

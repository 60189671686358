@import "./static/_variables.scss";


// Initial declarations
* { 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-family: $font-heebo;
    font-size: 16px;
    background-color: $background-color;
    color: $main-text-color;
}

h1 {
    font-size: $h1-size;
    font-weight: 400;
}

h2 {
    font-size: $h2-size;
    font-weight: 400;
}

h3 {
    font-size: $h3-size;
    font-weight: 400;
}

p {
    font-size: $p-size;
    font-weight: 300;
}


// Custom scrollbar
body::-webkit-scrollbar {
    display: block;
    background-color: $orange-light;
    width: 8px;
    overflow: auto;
}

body::-webkit-scrollbar-thumb {
    background-color: $orange-dark;
    border-radius: 10px;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

// Common classes
.main-container {
    @extend .flex-center;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 100px);
    padding: 3em;
    padding-bottom: 100px;
    overflow: scroll;
    transition: all 0.1s ease-in;
}

.main-container-start {
    @extend .main-container;
    align-items: flex-start
}

// about section
.about-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;

    .about-tag-container {
        width: $image-width;
    }
}

.contact-container {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.projects-container {
    @extend .flex-center;
    height: 100%;
    flex-direction: column;
}

.skills-container {
    height: 100%;
    width: 90%;
    max-width: 1500px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.7) 0px 3px 12px;
}


@media screen and (max-width: $ipad-width) {
    .main-container {
        padding-top: 12vh;
        align-items: flex-start;
        min-height: none;
    }
}

@media screen and (max-width: $phone-width) {
    .main-container {
        flex-direction: column;
        align-items: center;
        padding: 0em 1em 3em 1em;
    }

    .about-container {
        margin-top: 0;
        width: 90%;

        .about-tag-container {
            width: 60%;
        }
    }

    .contact-container {
        height: 60%;
        width: 100%;
        flex-direction: column;
        justify-content: space-around;
    }

    .skills-container {
        width: 90%;
    }
}

@import "../../../static/variables";

.profile-img {
    border-radius: 50%;
    max-width: 70%;
    width: $image-width;
    box-shadow: rgba(0, 0, 0, 0.7) 0px 3px 12px;
    margin-bottom: 50px;
}

@media screen and (max-width: $ipad-width) {
    .profile-img {
        box-shadow: rgba(0, 0, 0, 0.7) 0px 2px 8px;
        margin-top: 0.35em // To avoid shadow going behind navbar
    }
}

@media screen and (max-width: $phone-width) {
    .profile-img {
        margin-bottom: 0em;
    }
}

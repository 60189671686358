@import "../../../static/variables";

.skill-content-container {
    margin-top: 1em;

    a {
        position: relative;
        margin-top: 1em;
        color: $orange;
        text-decoration: none;
        display: inline-block;
    }
    a:hover {
        text-decoration: underline;
    }

    h3 {
        margin-top: 1.5em;
    }

    div {
        margin-top: 1em;
        width: 60%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 0.5em;

        p {
            margin-right: 1em;
        }
    }
}

@media screen and (max-width: $ipad-width) {
    .skill-content-container {
        margin-top: 0.3em;

        a {
            margin-top: 0.5em;
        }

        h3 {
            margin-top: 1em;
        }

        div {
            margin-top: 0.5em;
            grid-template-columns: 1fr 1fr 1fr;
            width: 80%;
        }
    }
}

@media screen and (max-width: $phone-width) {
    .skill-content-container {
        div {
            width: 100%;
        }
    }
}

@media screen and (max-width: $phone-width-small) {
    .skill-content-container {
        div {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@import "../../../static/_variables";

.tag-container {
    display: flex;
    flex-wrap: wrap;
}

.tag {
    font-size: calc(0.6em + 0.8vw);
    margin: 0 14px 16px 0;
    padding: 2px 7px;
    border-radius: 3px;
    background-color: $green;
    box-shadow: rgba(0, 0, 0, 0.7) 0px 2px 6px;
}

@media screen and (max-width: $phone-width) {
    .tag-container {
        max-width: 100%;
    }

    .tag {
        margin: 5px 5px;
    }
}

@import "../../static/variables";

.project-container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.project-image-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
}

.project-image {
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.7) 0px 2px 8px;
    width: calc(10em + 13vw);
    height: calc(10em + 13vw);
}

.see-project-button {
    width: calc(10em + 13vw);
    color: black;
    text-decoration: none;
    margin-top: 2em;
    padding: 8px 13px;
    border: none;
    border-radius: 5px;
    background-color: $orange;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 8px;
    display: flex;
    transition: all 0.2s ease;

    img {
        margin-left: calc(0.5em + 0.5vw);
        height: calc(0.9em + 0.5vw);;
        width: auto;
    }
}
.see-project-button:hover {
    transform: scale(1.1);
    background-color: $orange-dark;
}

.text-and-tags-container {
    width: 50%;
    max-width: 1000px;

    p {
        margin-bottom: 1em;
    }
}

@media screen and (max-width: $phone-width) {
    .project-container {
        margin-bottom: 2em;
        flex-direction: column;
    }

    .project-image {
        width: calc(12em + 16vw);
        height: calc(12em + 16vw);
    }

    .project-image-container{
        width: 100%;
        margin-right: 0;
        margin-bottom: 2em;
    }

    .see-project-button {
        width: calc(12em + 16vw);
    }
    .see-project-button:hover {
        background-color: $orange;
        transform: none;
    }

    .text-and-tags-container {
        padding: 0 5%;
        width: 100%;
    }
}